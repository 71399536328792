/* eslint-disable no-undef */
import orderService from "../../services";
import appConfig from "@/common/services/app-config";


export default {
    data() {
        return {
            order: null,
            hasOrderStatusAwaitingApproval: false,
            hasOrderStatusApproved: false,
            hasOrderStatusProcessingPayment: false,
            hasOrderStatusCreated: false,
            hasOrderStatusProcessingOrder: false,
            hasOrderStatusShipped: false,
            hasOrderStatusDelivered: false,
            hasOrderStatusPaymentFailed: false,
            completeProcessingStatus: false
        }
    },
    mounted() {
        this.showLoader(true);
        orderService.getOrder(this.$route.params.id).then(response => {
            this.hideLoader();
            this.order = response.data;

            if (this.order.status == 'Awaiting For Approval') {
                this.hasOrderStatusAwaitingApproval = true;
            }
            if (this.order.status == 'Awaiting For Payment') {
                this.hasOrderStatusApproved = true;
            }
            if (this.order.status == 'Created') {
                this.hasOrderStatusCreated = true;
            }
            if (this.order.status == 'Processing Payment') {
                this.hasOrderStatusProcessingPayment = true;
            }
            if (this.order.status == 'Processing Order') {
                this.hasOrderStatusProcessingOrder = true;
            }
            if (this.order.status == 'Shipped') {
                this.completeProcessingStatus = true;
                this.hasOrderStatusShipped = true;
            }
            if (this.order.status == 'Delivered') {
                this.completeProcessingStatus = true;
                this.hasOrderStatusDelivered = true;
            }
            if (this.order.status == 'Payment Failed') {
                this.hasOrderStatusPaymentFailed = true;
            }
        });
    },
    methods: {
        checkout() {
            this.showLoader(true);
            orderService.makePaymentForOrder(this.order.id).then(response => {
                this.payment(response.data);
                this.hideLoader();
            })
        },
        payment(data) {
            let order = data.payment;
            let lifelyOrder = data.order;
            var options = {
                "key": appConfig.razorpayApiKey.IN, // Enter the Key ID generated from the Dashboard
                "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": order.currency,
                "name": "Lifely",
                "description": `lifely order ${ order.notes.title }`,
                "image": "https://partner.lifely.world/images/lifely/logo-dark.png",
                "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": (response) => {
                    console.log(response);
                    this.$router.push({
                        name: 'order-success',
                        params: { id: lifelyOrder.id },
                        query: { orderId: lifelyOrder.title }
                    });
                },
                "prefill": {
                    "name": order.notes.customer_name,
                    "email": order.notes.customer_email,
                    "contact": order.notes.customer_phone
                },
                "notes": order.notes,
                "theme": {
                    "color": "#2c4c32"
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on('payment.failed', function (response) {
                console.log(response.error);
            });
            rzp1.open();
        }
    },
}
