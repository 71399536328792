<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center"
        v-if="$store.state.user.user_type != 'super_admin'">
        <i class="fas fa-shopping-bag font-22 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;"
        v-if="$store.state.user.user_type == 'super_admin'">B2B Orders</h1>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;"
        v-if="$store.state.user.user_type != 'super_admin'">Orders</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-3 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>
<app-footer />
<div class="page-title-clear"></div>
<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div id="tab-group-1">
        <div class="tab-controls card-style mx-2 mb-4 tabs-small tabs-rounded letterSpacing"
            data-highlight="bg-primary-light-color">
            <a href="#" class="line-height-s p-1 py-2 btn-ripple" data-active data-bs-toggle="collapse"
                @click="getOrders('pending')" data-bs-target="#tab-1ab">

                {{ statistics.pending ? statistics.pending : '0' }}
                <br />
                Pending
            </a>
            <a href="#" class="line-height-s p-1 py-2 btn-ripple" data-bs-toggle="collapse"
                @click="getOrders('Shipped')" data-bs-target="#tab-2ab">
                {{ statistics.shipped ? statistics.shipped : '0' }}
                <br />
                Shipped
            </a>
            <a href="#" class="line-height-s p-1 py-2 btn-ripple" data-bs-toggle="collapse"
                @click="getOrders('Delivered')" data-bs-target="#tab-3ab">
                {{ statistics.delivered ? statistics.delivered : '0' }}
                <br />
                Delivered
            </a>
            <a href="#" class="line-height-s p-1 py-2 btn-ripple" data-bs-toggle="collapse"
                @click="getOrders('Payment Failed')" data-bs-target="#tab-4ab">
                {{ statistics.failed ? statistics.failed : '0' }}
                <br />
                Failed
            </a>
        </div>

        <div data-bs-parent="#tab-group-1" class="collapse show" id="tab-1ab">
            <div v-for="record in orders" class="card card-style mb-2 mx-2">
                <div class="content my-2 mx-2">
                    <router-link :to="{'name': 'order-detail', params: {'id' : record.id}}">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column justify-content-between">
                                <h2 class="font-15 font-300 line-height-s mt-1 mb-1 text-secondary letterSpacing">
                                    {{ record.title }}
                                    <span class="primary-alt-base-text">/</span>
                                    <span class="font-13 ms-1 text-secondary">{{ localDate(record.date) }}</span>
                                </h2>
                                <div class="font-12 line-height-s mt-3 mb-1 letterSpacing">
                                    <span class="orderStatusPill" v-if="record.status">
                                        <i class="fas fa-pen" v-if="record.status.toLowerCase() == 'created'"></i>
                                        <i class="fas fa-clock"
                                            v-if="record.status.toLowerCase() == 'awaiting for approval'"></i>
                                        <i class="fas fa-clock" v-if="record.status.toLowerCase() == 'awaiting for payment'"></i>
                                        <i class="fas fa-clock"
                                            v-if="record.status.toLowerCase() == 'processing payment'"></i>
                                        <i class="fas fa-hourglass-end"
                                            v-if="record.status.toLowerCase() == 'processing order'"></i>
                                        {{ record.status }}
                                    </span>
                                    <span class="orderAmountPill font-12 ms-1 mb-0 text-end" v-if="record.total">
                                        {{record.country.currency_symbol}}{{ record.total }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="font-13 mb-0 font-600">
                                    {{ record.items.length }} {{ record.items.length > 1 ? 'Items' : 'Item' }}
                                </p>
                                <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!(orders.length > 0)">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Order Found
                </p>
            </div>
        </div>


        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-2ab">
            <div v-for="record in orders" class="card card-style mb-2 mx-2">
                <div class="content my-2 mx-2">
                    <router-link :to="{'name': 'order-detail', params: {'id' : record.id}}">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column justify-content-between">
                                <h2 class="font-15 font-300 line-height-s mt-1 mb-1 text-secondary letterSpacing">
                                    {{ record.title }}
                                    <span class="primary-alt-base-text">/</span>
                                    <span class="font-13 ms-1 text-secondary">{{ localDate(record.date) }}</span>
                                </h2>
                                <div class="font-12 line-height-s mt-3 mb-1 letterSpacing">
                                    <span class="orderStatusPill" v-if="record.status">
                                        <i class="fas fa-shipping-fast"></i>
                                        {{ record.status }}
                                    </span>
                                    <span class="orderAmountPill font-12 ms-1 mb-0 text-end font-600"
                                        v-if="record.total">
                                        {{record.country.currency_symbol}}{{ record.total }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="font-13 mb-0 font-600">
                                    {{ record.items.length }} {{ record.items.length > 1 ? 'Items' : 'Item' }}
                                </p>
                                <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!(orders.length > 0)">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Order Found
                </p>
            </div>
        </div>


        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-3ab">
            <div v-for="record in orders" class="card card-style mb-2 mx-2">
                <div class="content my-2 mx-2">
                    <router-link :to="{'name': 'order-detail', params: {'id' : record.id}}">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column justify-content-between">
                                <h2 class="font-15 font-300 line-height-s mt-1 mb-1 text-secondary letterSpacing">
                                    {{ record.title }}
                                    <span class="primary-alt-base-text">/</span>
                                    <span class="font-13 ms-1 text-secondary">{{ localDate(record.date) }}</span>
                                </h2>
                                <div class="font-12 line-height-s mt-3 mb-1 letterSpacing">
                                    <span class="orderStatusPill completeOrder" v-if="record.status">
                                        <i class="fas fa-check"></i>
                                        {{ record.status }}
                                    </span>
                                    <span class="orderAmountPill font-12 ms-1 mb-0 text-end font-600"
                                        v-if="record.total">
                                        {{record.country.currency_symbol}}{{ record.total }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="font-13 mb-0 font-600">
                                    {{ record.items.length }} {{ record.items.length > 1 ? 'Items' : 'Item' }}
                                </p>
                                <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!(orders.length > 0)">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Order Found
                </p>
            </div>
        </div>


        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-4ab">
            <div v-for="record in orders" class="card card-style mb-2 mx-2">
                <div class="content my-2 mx-2">
                    <router-link :to="{'name': 'order-detail', params: {'id' : record.id}}">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column justify-content-between">
                                <h2 class="font-15 font-300 line-height-s mt-1 mb-1 text-secondary letterSpacing">
                                    {{ record.title }}
                                    <span class="primary-alt-base-text">/</span>
                                    <span class="font-13 ms-1 text-secondary">{{ localDate(record.date) }}</span>
                                </h2>
                                <div class="font-12 line-height-s mt-3 mb-1 letterSpacing">
                                    <span class="orderStatusPill text-danger" style="border-color: rgb(220,52,68);"
                                        v-if="record.status">
                                        <i class="fas fa-exclamation-circle"></i>
                                        {{ record.status }}
                                    </span>
                                    <span class="orderAmountPill font-12 ms-1 mb-0 text-end font-600"
                                        v-if="record.total">
                                        {{record.country.currency_symbol}}{{ record.total }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="font-13 mb-0 font-600">
                                    {{ record.items.length }} {{ record.items.length > 1 ? 'Items' : 'Item' }}
                                </p>
                                <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!(orders.length > 0)">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Order Found
                </p>
            </div>
        </div>
    </div>
</div>


<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search "
                v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>