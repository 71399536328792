/* eslint-disable no-undef */
import outletService from "../../services";


export default {
    data() {
        return {
            outlet: null,
        }
    },
    mounted() {
        this.showLoader(true);
        outletService.getOutlet(this.$route.params.id).then(response => {
            this.hideLoader();
            this.outlet = response.data;
        });
    },
    methods: {

    },
}
