<app-header menu="no">
    <router-link :to="{name: 'outlet-home'}" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </router-link>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ outlet && outlet.outlet_name ?
        outlet.outlet_name : Outlet }}</h1>

</app-header>
<div class="page-title-clear"></div>

<div class="page-content">

    <!-- ORDER STATUSBAR START -->

    <div class="card card-style pt-2 mb-0 mt-3" v-if="outlet">
        <div class="d-flex flex-column justify-content-center align-items-center mb-4">
            <div class="mb-3 outlet-detail">
                <i class="fas fa-store-alt outlet-icon"></i>
            </div>
            <h2 class="outlet-name">
                {{ outlet.outlet_name }}
                <router-link :to="{'name': 'partner-detail', params: {'id' : outlet.user_id}}" class="outlet-user"
                    v-if="$store.state.user.user_type === 'super_admin'">
                    <i class="fas fa-user primary-alt-base-text"></i>
                </router-link>
            </h2>
            <div class="outlet-detailInfo">
                <h5 class="text-uppercase">( {{ outlet.outlet_type }} )</h5>
            </div>
            <div class="outlet-detailInfo mt-3">
                <h5 class="text-capitalize text-secondary">{{ outlet.address }}, {{ outlet.city.name }}</h5>
                <h5 class="text-capitalize text-secondary">{{ outlet.state.name }}, {{ outlet.country.name }} - {{
                    outlet.zipcode }}
                </h5>
            </div>
        </div>
    </div>

</div>
<app-footer />